<template>
  <div class="modal-card" :style="ModalWidth">
    <!-- <header class="modal-card-head whitebg" style="padding: 10px;">
      <p class="modal-card-title">Blueprint</p>
    </header> -->
    <section class="modal-card-body roundedtop">
      <div class="tab-item" v-if="loading" style="width: 200px;">
        <b-progress type="is-info"></b-progress>
      </div>
      <div v-if="!loading">
        <div>
          <img :src="Goal.blueprintimage">
        </div>
        <div class="txth3 mt9 mb9">{{Goal.goaltitle }}</div>
        <div class="tsk bluebg1 mt11 mb11 p11" v-if="installed">
          <div class="txth3 bluetxt10">You already own this blueprint</div>
          <div>You will find it in Projects in the Blueprints section.</div>
          <div class="mt9"><img src="/imgs/blueprintsicon.png" class="tskshadow" style="width: 319px;" /></div>
        </div>
        <div class="tsk bluebg1 mt11 mb11 p11" v-if="newinstall">
          <div class="txth3 bluetxt10">Thank you!</div>
          <div>Your purchase was successfull.</div>
          <div>The blueprint has been added to your collection. You will find it in Projects in the Blueprints section.</div>
          <div class="mt9"><img src="/imgs/blueprintsicon.png" class="tskshadow" style="width: 319px;" /></div>
        </div>
        <div>
          <div class="mb9">{{ Goal.description }}</div>
          <div>
            <div v-if="Goal.blueprinttype === 'Self'">Blueprint you can use as many times as you like and at your own pace.</div>
            <div class="tsk bluebg1" v-if="Goal.blueprinttype === 'Review'"><strong>An instructor will review you progress and give helpfull advice.</strong><br/> Once you have ordered the blueprint the instructor will create the Goal and invite you to join the Goal. The instructor will also be in contact with you to arrange times and days that suit both of you.</div>
            <div class="tsk bluebg1" v-if="Goal.blueprinttype === 'Lead'">
              <strong>This is an instructor lead course on specific days.</strong>
            </div>
          </div>
          <div class=" tsk isFlex isFlexSpace">
            <div>Cost:</div>
            <div v-if="Goal.blueprintcosttype === 'Free'">FREE</div>
            <div v-if="Goal.blueprintcosttype === 'Paid'">&pound; {{ Goal.blueprintcost }}</div>
            <div v-if="Goal.blueprintcosttype === 'Monthly'">&pound; {{ Goal.blueprintcost }}/m</div>
          </div>
          <div class=" tsk isFlex isFlexSpace">
            <div>Starts:</div>
            <div>{{ Goal.duedate | displaydateFull }}</div>
          </div>
          <div class=" tsk isFlex isFlexSpace" v-if="Goal.blueprinttype === 'Review' || Goal.blueprinttype === 'Lead'">
            <div>Allowed Students:</div>
            <div>{{ Goal.blueprintmembers }}</div>
          </div>
          <div class=" tsk isFlex isFlexSpace" v-if="Goal.blueprinttype === 'Lead'">
            <div>Availability:</div>
            <div>{{ Goal.blueprintmembers - (Goal.availableto.length - 1) }}</div>
          </div>
          <div style="height: 20px;"></div>
          <div class="txth4">Outline</div>

          <ul class="ul">
            <task-item v-for="(task, index) in TreeData" :key="index" :item="task"></task-item>
          </ul>
        </div>

      </div>
    </section>
    <footer class="modal-card-foot isFlex" style="padding: 10px;" v-if="!loading"> 
      <div class="isFlexGrow">
        <b-button @click="$parent.close()">
          Close
        </b-button>
      </div>
      <div v-if="Goal.blueprintcosttype === 'Free'">
        <b-button @click="installBlue()" v-if="Goal.blueprinttype === 'Self'">Add to library</b-button>
        <b-button @click="requestBlue()" v-if="Goal.blueprinttype === 'Review'">Request</b-button>
        <b-button @click="joinBlue()" v-if="Goal.blueprinttype === 'Lead'">Join Class</b-button>
      </div>
      <div v-else>
        <b-button @click="buyBlue()" v-if="Goal.blueprinttype === 'Self'">Buy</b-button>
        <b-button @click="buyBlue()" v-if="Goal.blueprinttype === 'Review'">Order a session</b-button>
        <b-button @click="buyBlue()" v-if="Goal.blueprinttype === 'Lead'">Secure your place</b-button>
      </div>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/mixins/utils.js";
import { Schemas } from "@/schemas/all.js";
import ObjectID from "bson-objectid";
import array from "lodash/array";

Vue.component("task-item", {
  template: "task-template",
  props: {
    item: Object
  },
  data: function () {
    return {
      task: this.item
    }
  },
  template: `<li><div class="txtbold">{{task.tasktext}}</div><div class="txtsm">{{task.taskdescription}}</div><ul class="ul" v-if="task.steps.length !== 0"><task-item v-for="(task, index) in task.steps" :key="index" :item="task"></task-item></ul></li>`
});

export default {
  name: "ModalMapForm",
  mixins: [Utils, Schemas],
  props: {
    data: Object,
  },
  data() {
    return {
      ModalWidth: `width: auto`,
      GoalIn: this.data,
      Data: {},
      loading: true,
      TreeData: [],
      installData: [],
      newinstall: false,
      installed: false,
    };
  },
  created() {
    this.DataLoad();
  },
  methods: {
    async DataLoad() {
      const ingoal = await this.DataGet(`/goal/${this.GoalIn._id}`);
      this.Goal = ingoal;
      const inblue = await this.DataGet(`/blueprintcheck/${this.GoalIn._id}`, '');
      if (inblue.message === 'yes') {
        this.installed = true;
      }
      const data = {
        parentid: this.Goal._id,
        parenttype: "goal",
        deleted: false,
        workflowtype: "ne",
        workflow: "Completed",
        sortby: "childorder",
      };
      
      const tasks = await this.DataSend("post", "/taskstree", data, "");
      this.TreeData = tasks.rows;
      this.setWidth();
      this.loading = false;
    },
    setWidth() {
      const inw = document.body.clientWidth;
      const inh = document.body.clientHeight;
      let newsize = `width: auto`;
      if (inw > 750) {
       const usize = inh - 100;
        newsize = `width: 700px; margin-left: 25px; margin-right: 25px; height: ${usize}px;`;
        // newsize = `width: ${usize}px; height: 80%;`;
      }
      this.ModalWidth = newsize;
    },

    processChildren(rows, p, newgoalid) {
      for (const t of rows) {
        const nid = ObjectID().str;
        const nr = {
          oldid: t._id,
          newid: nid,
          parentid: p,
          goalid: newgoalid,
          text: t.tasktext
        }
        this.installData.push(nr);
        if (t.steps.length !== 0) {
          this.processChildren(t.steps, nid, newgoalid);
        }
      }
    },

    async installBlue() {
      const newgoalid = new ObjectID().str;
      this.installData = [];
      const data = {
        oldgoalid: this.Goal._id,
        newgoalid: newgoalid,
        tasks: []
      }
      for (const t of this.TreeData) {
        const id = new ObjectID().str;
        const nr = {
          oldid: t._id,
          newid: id,
          parentid: '',
          goalid: newgoalid,
          text: t.tasktext
        }
        this.installData.push(nr);
        if (t.steps.length !== 0) {
          this.processChildren(t.steps, id, newgoalid);
        }
      }
      data.tasks = this.installData;
      const installed = await this.DataSend('post', '/blueprint/install', data);
      this.newinstall = true;
    },

    async joinBlue() {
      const avail = this.Goal.blueprintmembers - (this.Goal.availableto.length - 1);
      if(avail === 0) {
        this.$buefy.snackbar.open({ message: "Sorry, no available slots.", position: "is-top" });
        return;
      }
      const sub = localStorage.getItem("sub");
      const hasrecord = array.findLastIndex(this.Goal.availableto, { '_id': sub });
      if (hasrecord !== -1) {
        this.$buefy.snackbar.open({ message: "You have already joined", position: "is-top" });
        return;
      }
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Join this class?",
        confirmText: "Join",
        type: "is-info",
        hasIcon: true,
        onConfirm: async () => {
          const jn = await this.DataGet(`/blueprintjoin/${this.Goal._id}`, '');
          if(jn.message === 'Joined') {
            this.Goal.availableto.push(jn.rows);
            this.$buefy.snackbar.open({ message: "You have successfully joined this class", position: "is-top" });
          } else {
            this.$buefy.snackbar.open({ message: "Sorry, no spaces available", position: "is-top" });
          }
        }
      });
      
    },

    async requestBlue() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Request instructor to guide you",
        confirmText: "Request",
        type: "is-info",
        hasIcon: true,
        onConfirm: async () => {
          const jn = await this.DataGet(`/blueprintrequest/${this.Goal._id}`, '');
          if(jn.message === 'Requested') {
            this.$buefy.snackbar.open({ message: "You have successfully requested the instructor to guide you.", position: "is-top" });
          } else {
            this.$buefy.snackbar.open({ message: "You have already sent a request", position: "is-top" });
          }
        }
      });
      
    },
  }
};
</script>

<style>
.ul {
  list-style-type: circle;
  padding-left: 20px;
}
</style>
