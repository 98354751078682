<template>
  <div class="contentwrapper">
      <div class="contentheadersub">
        
      </div>
      <div class="isFlexGrow" style="position: relative;">
        <div class="contentsroll">
          <!-- content: start -->
          <div class="tsk tsk300 tskshadow m11 isFlex isFlexCol" v-for="(g, index) in Blueprints" :key="index" style="float: left; height: 350px">
            <div @click="openBlueprint(g)" class="isFlexGrow isPointer">
              <div><img :src="g.blueprintthumb" /></div>
              <div class="txtbold txtlg">{{ g.goaltitle }}</div>
              <div v-if="g.blueprinttype === 'Self'">Blueprint you can use as many times as you like and at your own pace.</div>
              <div v-if="g.blueprinttype === 'Review'">An instructor will review you progress and give helpfull advice.</div>
              <div v-if="g.blueprinttype === 'Lead'">An instructor lead course on specific days.</div>
              <div class="txtsm txtitalic">{{ g.subname }}</div>
          </div>
          <div class="isFlex isFlexSpace">
              <div><font-awesome-icon :icon="['fal', 'tasks']" size="sm"></font-awesome-icon> {{ g.taskcount }}</div>
              <div v-if="g.blueprintcosttype === 'Free'">FREE</div>
              <div v-if="g.blueprintcosttype === 'Paid'">&pound; {{ g.blueprintcost }}</div>
              <div v-if="g.blueprintcosttype === 'Monthly'">&pound; {{ g.blueprintcost }}/m</div>
            </div>
          </div>
          <!-- content: end -->
        </div>
      </div>
      <div class="contentfooter isFlex vlgreybg p7"></div>
    </div>
</template>

<script>
// @ is an alias to /src
import { EventBus } from '../event-bus.js';
import { Utils } from '../mixins/utils.js';
import ModalBlueprint from "@/modals/ModalBlueprint.vue";

export default {
  mixins: [Utils],
  name: 'blank',
  data() {
    return {
      Blueprints: []
    }
  },
  created() {
    this.getBlueprints();
  },

  methods: {
    async getBlueprints() {
      const data = {};
      const getB = await this.DataSend('post', '/blueprints', data, 'rows');
      this.Blueprints = getB;
    },

    openBlueprint(row) {
      this.modal = this.$buefy.modal.open({
      parent: this,
      component: ModalBlueprint,
      hasModalCard: true,
      props: {
        data: row
      },
      events: {
        newRowAdded: value => {
          this.Locations.push(value);
        }
      }
    });
    }
  }
}
</script>